import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { formatFinance, getMainRequisiteData } from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import ToggleButton from "./togglebutton/ToggleButton";
import ModalAddRequisite from "./modal/ModalAddRequisite";
import ModalEditRequisite from "./modal/ModalEditRequisite";

import LinearProgressBar from "./linearprogressbar/LinearProgressBar";

import { AddShopPlusIcon, EditIcon } from "./Icons";

const TradeRequisites = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalEditActive, setModalEditActive] = useState(false);
  const [requisiteData, setRequisiteData] = useState({});
  const [pageRequisites, setPageRequisites] = useState(1);
  const [trigger, setTrigger] = useState(0);
  const count = 10;
  const requisitesStatus = ["Отключен", "Доступен"];

  const getRequisiteStatus = (available) => {
    const index = parseInt(available);
    if (index >= 0 && index < requisitesStatus.length) {
      return requisitesStatus[index];
    } else {
      return "Архивный";
    }
  };

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: "/trader/requisites",
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingRequisitesUpdate }, executePostRequisitesEdit] =
    useAxios(
      {
        url: "/trader/requisites/edit",
        method: "POST",
      },
      axiosConfig
    );

  const handleToogleAvailable = (id, available) => {
    executePostRequisitesEdit({
      data: {
        requisite_id: id,
        available: available === 0 ? true : false,
      },
    })
      .catch((error) => {
        // console.log("executePostStatus", error);
      })
      .finally(() => {
        setTrigger(trigger + 1);
      });
  };

  const handleModalActivity = (active = true) => {
    setModalActive(active);
  };

  const handleModalEditActivity = (active = true) => {
    setModalEditActive(active);
  };

  const handleModalEditRequisite = (index) => {
    setRequisiteData(data?.requisites[index]);
    setModalEditActive(true);
  };

  useEffect(() => {
    if (!modalActive && !modalEditActive) {
      executePost({
        data: {
          page: pageRequisites,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageRequisites, count, modalActive, modalEditActive, trigger]);

  let pageCountQuery = Math.ceil(data?.countRequisites / count);

  if (loading || loadingRequisitesUpdate)
    return (
      <div style={{ display: "flex" }}>
        <Spinner />
      </div>
    );

  return (
    <>
      <ModalAddRequisite active={modalActive} setActive={handleModalActivity} />
      <ModalEditRequisite
        active={modalEditActive}
        setActive={handleModalEditActivity}
        requisiteData={requisiteData}
      />
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Реквизиты
        </div>
        <button
          className="btn btn_add"
          id="add-project"
          onClick={handleModalActivity}
        >
          <AddShopPlusIcon />
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Тип</th>
                  <th>Реквизиты</th>
                  <th>Лимит</th>
                  <th>Заполнено</th>
                  <th>Доступность</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {data?.requisites.map(
                  (
                    {
                      id,
                      type,
                      requisite_details,
                      min_amount,
                      max_amount,
                      total_amount,
                      used_total_amount,
                      createdAt,
                      available,
                    },
                    index
                  ) => (
                    <tr key={id}>
                      <td data-label="ID">{id}</td>
                      <td data-label="Тип">{type}</td>
                      <td data-label="Реквизиты">
                        {getMainRequisiteData(requisite_details)}
                      </td>
                      <td data-label="Лимит">
                        {formatFinance(min_amount)} -{" "}
                        {formatFinance(max_amount)} ₽
                      </td>
                      <td data-label="Заполнено">
                        {formatFinance(used_total_amount)} <br />
                        из {formatFinance(total_amount)} ₽ <br />
                        {
                          <LinearProgressBar
                            currentValue={used_total_amount}
                            limitValue={total_amount}
                          />
                        }
                      </td>
                      <td data-label="Доступность">
                        <ToggleButton
                          onToggle={(data) => {
                            handleToogleAvailable(id, available);
                          }}
                          initialState={available === 0 ? false : true}
                        />
                      </td>
                      <td data-label="Действия">
                        <button
                          className="btn btn-xs btn-warning btn_minimal"
                          onClick={() => {
                            handleModalEditRequisite(index);
                          }}
                        >
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageRequisites}
          setPage={setPageRequisites}
          pageCountQuery={pageCountQuery}
        />
      }
    </>
  );
};

export default TradeRequisites;
