import React, { useState } from "react";
import useAxios, { configure } from "axios-hooks";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import { AcceptIcon } from "../Icons";
import { $authHost, axiosConfig } from "../../common/api";
import { isOtpCode, isPdfPath } from "../../common/utils";

import { CDN_PUBLIC_URL, TRADE_MODE } from "../../common/constants";

const ModalViewAppeal = ({ active, setActive, appealData }) => {
  const [otpCode, setOtpCode] = useState("");
  const [commentText, setCommentText] = useState("");
  const [actionType, setActionType] = useState("");

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/appeals/changestatus",
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingStatus }, executePostStatus] = useAxios(
    {
      url: "/trader/deals/changestatus",
      method: "POST",
    },
    axiosConfig
  );

  let errors = {
    verify_2fa: isOtpCode(otpCode)
      ? null
      : {
          message: "Допустимо только 6 цифр",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  const handleOnClose = () => {
    setOtpCode("");
    setCommentText("");
    setActive(false);
  };

  const handleChangeStatusAppeal = () => {
    executePost({
      data: {
        appeal_id: appealData.id,
        trader_note: commentText,
        state: "cancel",
      },
    })
      .then((data) => {
        handleOnClose();
        toast.success("Статус апелляции успешно изменён");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleChangeStatusDeal = () => {
    executePostStatus({
      data: {
        deal_id: appealData.deal_id,
        otp_code: otpCode,
        state: "close",
      },
    })
      .then((data) => {
        handleOnClose();
        toast.success("Статус апелляции успешно изменён");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  if (!active || !appealData || !appealData?.payment_id) {
    return null;
  }
  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal8"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Просмотр апелляции</div>
          <div
            className="dialog__head-close"
            id="modal8Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          {appealData?.trader_note && (
            <div className="custom-input custom-input_block">
              <label style={{ color: "red" }} htmlFor="appeal-trader-note">
                Причина отклонения
              </label>
              <TextareaAutosize
                name="appeal-trader-note"
                id="appeal-trader-note"
                style={{
                  lineHeight: 1.5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                }}
                cacheMeasurements
                readOnly={true}
                value={appealData?.trader_note}
              />
            </div>
          )}

          <div className="custom-input custom-input_block">
            <label htmlFor="appeal-payment-id">Id платежа</label>
            <input
              value={appealData?.payment_id}
              disabled
              type="text"
              id="appeal-payment-id"
              autoComplete="off"
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="appeal-note">Комментарий</label>
            <TextareaAutosize
              name="appeal-note"
              id="appeal-note"
              style={{
                lineHeight: 1.5,
                paddingTop: 10,
                paddingBottom: 10,
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              cacheMeasurements
              readOnly={true}
              value={appealData?.note}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label>Приложенный файл</label>
          </div>

          {isPdfPath(appealData?.url) ? (
            <embed
              width={"100%"}
              height={500}
              src={CDN_PUBLIC_URL + appealData?.url}
              style={{
                borderRadius: 10,
                marginTop: 10,
              }}
            />
          ) : (
            <img
              width={"100%"}
              src={CDN_PUBLIC_URL + appealData?.url}
              style={{
                borderRadius: 10,
                marginTop: 10,
              }}
            />
          )}

          {TRADE_MODE && appealData?.status === 0 && (
            <>
              {actionType === "" && (
                <>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-xs btn-draft"
                    onClick={() => {
                      setActionType("reject");
                    }}
                  >
                    Отклонить
                  </button>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-xs btn-success"
                    onClick={() => {
                      setActionType("accept");
                    }}
                  >
                    Принять
                  </button>
                </>
              )}
              {actionType === "reject" && (
                <>
                  <div className="custom-input custom-input_block">
                    <label htmlFor="appeal-note">
                      Комментарий к отклонению
                    </label>
                    <TextareaAutosize
                      name="appeal-note"
                      id="appeal-note"
                      style={{
                        lineHeight: 1.5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: "white",
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                      }}
                      cacheMeasurements
                      value={commentText}
                      autoFocus={true}
                      autoComplete="off"
                      placeholder="Введите данные"
                      onChange={(e) => setCommentText(e.target.value)}
                    />
                  </div>
                  <button
                    className="btn btn-success"
                    disabled={loading || commentText === ""}
                    onClick={() => {
                      handleChangeStatusAppeal();
                    }}
                    style={{ marginTop: 40 }}
                  >
                    Подтвердить
                  </button>
                </>
              )}

              {actionType === "accept" && (
                <div style={{ marginTop: 20 }}>
                  <h4>Принять апелляцию</h4>
                  <span>Введи 6-ти значный OTP код</span>
                  <div className="custom-input custom-input_block">
                    <label htmlFor="verify-2fa">Код OTP</label>
                    <input
                      name="verify-2fa"
                      type="text"
                      id="verify-2fa"
                      placeholder="123123"
                      autoComplete="off"
                      onChange={(e) => setOtpCode(e.target.value)}
                      value={otpCode}
                    />
                    {getErrorBlock(errors?.verify_2fa)}
                  </div>
                  <button
                    className="btn btn-success"
                    disabled={loadingStatus || errors?.verify_2fa}
                    onClick={() => {
                      handleChangeStatusDeal();
                    }}
                    style={{ marginTop: 40 }}
                  >
                    Подтвердить
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalViewAppeal;
