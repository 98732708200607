import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TRADE_MODE } from "./common/constants";
import AuthScreen from "./components/AuthScreen";
import TraderScreen from "./components/TraderScreen";
import DashboardScreen from "./components/DashboardScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RoutesContainer = () => {
  if (TRADE_MODE) {
    return (
      <Routes>
        <Route path="/dashboard/:slug/:itemId/edit" element={<TraderScreen />} />
        <Route path="/dashboard/:slug" element={<TraderScreen />} />
        <Route path="/dashboard/" element={<TraderScreen />} />
        <Route path="/" element={<AuthScreen />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route
          path="/dashboard/:slug/:shopId/:tab"
          element={<DashboardScreen />}
        />
        <Route path="/dashboard/:slug/:shopId" element={<DashboardScreen />} />
        <Route path="/dashboard/:slug" element={<DashboardScreen />} />
        <Route path="/dashboard/" element={<DashboardScreen />} />
        <Route path="/" element={<AuthScreen />} />
      </Routes>
    );
  }
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <RoutesContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
