import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { isLogin } from "../common/userApi";
import Header from "./Header";
import Aside from "./Aside";
import TradeDeals from "./TradeDeals";
import TradeRequisites from "./TradeRequisites";
import TradeAppeals from "./TradeAppeals";
import Settings from "./Settings";
import Wallet from "./Wallet";

const TraderScreen = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [hamburgerActive, setHamburgerActive] = useState(false);

  configure({ axios: $authHost });

  const [{ data, loading }, refetch] = useAxios(
    "/trader/settings",
    axiosConfig
  );

  useEffect(() => {
    setHamburgerActive(false);
    if (isLogin()) {
      refetch().catch((error) => {
        // console.log("refetch", error);
      });
    } else {
      navigate("/");
    }
  }, [params]);

  const isDefault = () => {
    return Object.keys(params).length === 0;
  };

  const isPage = (page) => {
    return Object.keys(params).length === 1 && params?.slug === page;
  };

  const getSlug = () => {
    return params?.slug ? params?.slug : "deals";
  };

  return (
    <div className="container">
      <Header
        hamburgerActive={hamburgerActive}
        setHamburgerActive={setHamburgerActive}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <Aside
        hamburgerActive={hamburgerActive}
        user={data?.user}
        slug={getSlug()}
        trade={true}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <div className="main">
        {(isPage("deals") || isDefault()) && <TradeDeals />}
        {isPage("requisites") && <TradeRequisites />}
        {isPage("appeals") && <TradeAppeals />}
        {isPage("wallet") && <Wallet user={data?.user} />}
        {isPage("settings") && <Settings user={data?.user} />}
      </div>
    </div>
  );
};

export default TraderScreen;
