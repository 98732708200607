import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { formatFinance, getShortRequisiteData } from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";

import useDayjsFormat from "../hooks/useDayjsFormat";

import ModalViewAppeal from "./modal/ModalViewAppeal";

const TradeAppeals = (props) => {
  const [pageAppeals, setPageAppeals] = useState(1);
  const [modalActive, setModalActive] = useState(false);
  const [appealData, setAppealData] = useState({});

  const count = 5;

  const appealStatus = ["Ожидает", "Принята", "Отклонена"];

  const getAppealStatus = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < appealStatus.length) {
      return appealStatus[index];
    } else {
      return "В архиве";
    }
  };

  const appealStyles = [
    "btn-innactive btn-xs btn-warning",
    "btn-innactive btn-xs btn-innactive_success",
    "btn-innactive btn-xs btn-innactive_error",
  ];

  const getAppealStyle = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < appealStyles.length) {
      return appealStyles[index];
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDate, getFullTime } = useDayjsFormat();

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/trader/appeals`,
      method: "POST",
    },
    axiosConfig
  );

  const handleViewAppeal = (index) => {
    setAppealData(data?.appeals[index]);
    setModalActive(true);
  };

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          page: pageAppeals,
          count: count,
        },
      }).catch((error) => {});
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageAppeals, count, modalActive]);

  let pageCountQuery = Math.ceil(data?.count_appeals / count);

  if (loading)
    return (
      <div className="project-details__tab project-details__tab_active">
        <div className="project-details__table mt-30">
          <div style={{ display: "flex" }}>
            <Spinner />
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Апелляции
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Платеж</th>
                  <th>Реквизиты</th>
                  <th>Сумма</th>
                  <th>Время</th>
                  <th>Статус</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {data?.appeals.map(
                  (
                    {
                      id,
                      payment_id,
                      note,
                      payment_note,
                      amount_to_pay,
                      createdAt,
                      status,
                    },
                    index
                  ) => (
                    <tr key={id}>
                      <td data-label="ID">{id}</td>
                      <td data-label="Платеж">{payment_id}</td>
                      <td data-label="Реквизиты">
                        {getShortRequisiteData(payment_note)}
                      </td>
                      <td data-label="Сумма">{formatFinance(amount_to_pay)} ₽</td>
                      <td data-label="Время">
                        {getFullTime(createdAt)} <br />
                        {getFullDate(createdAt)}
                      </td>
                      <td data-label="Статус">
                        <span className={getAppealStyle(status)}>
                          {getAppealStatus(status)}
                        </span>
                      </td>
                      <td data-label="Действия">
                        <button
                          className="btn btn-xs btn-success btn_minimal"
                          onClick={() => {
                            handleViewAppeal(index);
                          }}
                        >
                          Подробно
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageAppeals}
          setPage={setPageAppeals}
          pageCountQuery={pageCountQuery}
        />
      }
      <ModalViewAppeal
        active={modalActive}
        setActive={setModalActive}
        appealData={appealData}
      />
    </>
  );
};

export default TradeAppeals;
