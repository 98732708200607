import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RECAPTCHA_PUBLIC_KEY, TRADE_MODE } from "../common/constants";
import { setRegistration, setLogin, isLogin } from "../common/userApi";
import { ChangeVisible2Icon } from "./Icons";

const AuthScreen = () => {
  const isTrader = TRADE_MODE;
  const [progress, setProgress] = useState(false);
  const [verify, setVerify] = useState(false);
  const [loginTab, setLoginTab] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [changeVisible, setChangeVisible] = useState({
    one: false,
    two: false,
  });

  const navigate = useNavigate();
  const navigateMainScreen = "/dashboard";

  useEffect(() => {
    if (isLogin()) {
      navigate(navigateMainScreen);
    }
  }, [navigate]);

  const handleChangeReCaptcha = (value) => {
    if (value) {
      setVerify(true);
      setRecaptchaValue(value);
    }
  };

  const handleLoginAction = () => {
    setLoginTab(true);
  };
  const handleRegistrationAction = () => {
    setLoginTab(false);
  };

  const registerSchema = Yup.object().shape({
    login: Yup.string()
      .required("Введите логин")
      .min(4, "Логин должен быть больше 3 символов"),
    password: Yup.string()
      .required("Введите пароль")
      .min(4, "Пароль должен быть больше 3 символов"),
    repeatPassword: !loginTab
      ? Yup.string()
          .required("Введите пароль повторно")
          .oneOf([Yup.ref("password")], "Пароли не совпадают")
      : null,
    email: !loginTab
      ? Yup.string()
          .required("Введите email")
          .email("Введите корректный email")
          .min(6, "Email должен быть больше 5 символов")
      : null,
  });

  const {
    register,
    formState: { errors: err },
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(registerSchema) });

  const onSubmit = async ({ login, password, repeatPassword, email }) => {
    try {
      if (loginTab) {
        setProgress(true);
        const response = await setLogin(
          login,
          password,
          recaptchaValue,
          isTrader
        );
        if (response) {
          reset();
          setProgress(false);
          navigate(navigateMainScreen);
        }
      } else {
        if (password !== repeatPassword) {
          return toast.error("Пароли не совпали");
        }
        setProgress(true);
        const response = await setRegistration(
          login,
          password,
          email,
          recaptchaValue,
          isTrader
        );
        if (response) {
          reset();
          setProgress(false);
          navigate(navigateMainScreen);
        }
      }
    } catch (error) {
      // console.log("Auth", error?.response?.data?.error);
      setProgress(false);
      if (error?.response?.data?.error) {
        return toast.error(error?.response?.data?.error);
      } else {
        return toast.error("Введите корректные данные");
      }
    }
  };

  const getInputClass = (error) => {
    return (
      "custom-input custom-input_block custom-input-mt35" +
      (error ? " input_error" : "")
    );
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -20,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  return (
    <div id="container">
      <div className="blur_bg"></div>
      <div className="main_block">
        <div className="left_block" style={{ height: 700 }}>
          <div className="title">
            {" "}
            {loginTab ? "Авторизация" : "Регистрация"}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block_login">
              <div className={getInputClass(err?.login)}>
                <label htmlFor="public-key" className="title_input">
                  Логин
                </label>
                <input
                  {...register("login")}
                  className="auth_input"
                  type={"text"}
                  id="public-key"
                  autoComplete="off"
                  placeholder={"Введите данные"}
                  name="login"
                />
                {getErrorBlock(err.login)}
              </div>
            </div>

            <div className="block_password">
              <div className={getInputClass(err?.password)}>
                <label htmlFor="secret-key" className="title_input">
                  Пароль
                </label>
                <input
                  {...register("password")}
                  className="auth_input"
                  type={changeVisible.one ? "text" : "password"}
                  id="secret-key"
                  autoComplete="off"
                  placeholder="Введите данные"
                  name="password"
                />
                <button
                  className="change-visibility"
                  onClick={(e) => {
                    e.preventDefault();
                    setChangeVisible({
                      ...changeVisible,
                      one: !changeVisible.one,
                    });
                  }}
                >
                  <ChangeVisible2Icon />
                </button>
                {getErrorBlock(err?.password)}
              </div>
              {!loginTab ? (
                <>
                  <div className={getInputClass(err?.repeatPassword)}>
                    <label htmlFor="repeat-secret-key" className="title_input">
                      Повторите пароль
                    </label>
                    <input
                      {...register("repeatPassword")}
                      className="auth_input"
                      type={changeVisible.two ? "text" : "password"}
                      id="repeat-secret-key"
                      autoComplete="off"
                      placeholder="Введите данные"
                      name="repeatPassword"
                    />
                    <button
                      className="change-visibility"
                      onClick={(e) => {
                        e.preventDefault();
                        setChangeVisible({
                          ...changeVisible,
                          two: !changeVisible.two,
                        });
                      }}
                    >
                      <ChangeVisible2Icon />
                    </button>
                    {getErrorBlock(err?.repeatPassword)}
                  </div>
                  <div className="block_login">
                    <div className={getInputClass(err?.email)}>
                      <label htmlFor="email-key" className="title_input">
                        Почта
                      </label>
                      <input
                        {...register("email")}
                        className="auth_input"
                        type={"text"}
                        id="email-key"
                        autoComplete="off"
                        name="email"
                        placeholder="Введите данные"
                      />
                      {getErrorBlock(err?.email)}
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            {loginTab ? (
              <>
                <button
                  disabled={!verify || progress}
                  type={"submit"}
                  className="btn btn-success btn-auth"
                >
                  Войти
                </button>
              </>
            ) : (
              <>
                <button
                  disabled={!verify || progress}
                  type={"submit"}
                  className="btn btn-success btn-auth"
                  style={{ margin: "40px auto 0 auto !important" }}
                >
                  Продолжить
                </button>
              </>
            )}
          </form>

          {loginTab ? (
            <>
              <button
                className="btn btn-success btn-dn"
                onClick={handleRegistrationAction}
              >
                Регистрация
              </button>
              <div
                className="custom-input custom-input_block"
                style={{ marginTop: 20 }}
              ></div>
            </>
          ) : (
            <button
              className="btn btn-success btn-dn"
              onClick={handleLoginAction}
            >
              Авторизация
            </button>
          )}
          {
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <ReCAPTCHA
                sitekey={RECAPTCHA_PUBLIC_KEY}
                onChange={handleChangeReCaptcha}
              />
            </div>
          }
        </div>
        <div className="right_block">
          <div className="logo" style={{ marginBottom: 25, fontSize: 35 }}>
            RosPlat
          </div>
          <div className="title_desc">
            {loginTab ? "Еще не зарегистрированы?" : "Уже есть аккаунт?"}
          </div>
          <div className="title_desc-grey">
            {loginTab
              ? "Это стоит исправить. Регистрируйся на сайте и получай приятные \n бонусы к платежам"
              : "Тогда авторизуйтесь в него, приятного дня!"}
          </div>
          {loginTab ? (
            <button
              className="btn btn-success btn-auth-registration"
              onClick={handleRegistrationAction}
            >
              Регистрация
            </button>
          ) : (
            <button
              className="btn btn-success btn-auth-registration"
              onClick={handleLoginAction}
            >
              Авторизация
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;
