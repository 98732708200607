const TRADE_MODE =
  (process.env.REACT_APP_TRADE_MODE || "false") === "true" ? true : false;
const API_URL = process.env.REACT_APP_API_URL || "https://api.rosplat.cash/";
const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_KEY_DEV || "";
const API_DOCS_URL = process.env.REACT_APP_API_DOCS || "";
const SUPPORT_TELEGRAM_URL = process.env.REACT_APP_SUPPORT_TELEGRAM || "";
const CDN_PUBLIC_URL =
  process.env.REACT_APP_CDN_PUBLIC_URL ||
  "https://ros.fra1.digitaloceanspaces.com/";

export {
  TRADE_MODE,
  RECAPTCHA_PUBLIC_KEY,
  API_URL,
  API_DOCS_URL,
  SUPPORT_TELEGRAM_URL,
  CDN_PUBLIC_URL,
};
